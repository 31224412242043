define("discourse/plugins/discourse-full-screen-videos-theme/lib/discourse-markdown/full-screen-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.whiteList(['iframe[allowfullscreen]']);
    helper.whiteList(['iframe[mozAllowFullScreen]']);
    helper.whiteList(['iframe[allow]']);
    helper.whiteList(['iframe[webkitallowfullscreen]']);
  }
});